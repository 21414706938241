/* eslint-disable react-hooks/exhaustive-deps */

import "./EditProfile.scss";

import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../utils/loader/Loader";
import { useBusinessProfileApis } from "../../custom_hooks/useBusinessProfileApis";
import { useSelector } from "react-redux";

//import DeleteIcon from "@mui/icons-material/Delete";

// Type definitions for the transformed object
type LocationType = {
  Address1: string;
  Address2: string;
  LandMark: string;
  City: string;
  ZipCode: string;
  Region: string;
  Country: string;
};

type TransformedObject = {
  Name: string;
  Description: string;
  Website: string;
  ProductsServices: string[];
  LocationType: string;
  Locations: any; // Modify this to an array of locations
  CustomerPersonas: { personName: string; personDescription: string }[] | null;
  SellingPoints: string[] | null;
  BusinessObjective: string | null;
  Competitors: { name: string; website: string }[] | null;
  UserUniqueId: string;
  UniqueId: string;
};

type UpdateTransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas: { personName: string; personDescription: string }[] | null;
  sellingPoints: string[] | null;
  businessObjective: string | null;
  competitors: { name: string; website: string }[] | null;
  UserUniqueId: string;
  uniqueId: string;
};

const EditBusinessProfile: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    GetBusinessProfilesDataByUniqueID,
    UpdateBusinessProfilesByUniqueID,
    businessProfilesDataLoading,
  } = useBusinessProfileApis();

  const { businessData } = location.state || {}; // Get the passed business data

  const businessRef = useRef(true);
  useEffect(() => {
    if (businessRef.current) {
      GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
      businessRef.current = false;
    }
  }, [businessData]);

  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  ); 

  const [updateProfileData, setUpdateProfileData] =
    useState<TransformedObject>(profileData);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [selectedLocationIndex, setSelectedLocationIndex] = useState<
    number | null
  >(null);

  const [formData, setFormData] = useState<LocationType>({
    Address1: "",
    Address2: "",
    LandMark: "",
    City: "",
    ZipCode: "",
    Region: "",
    Country: "",
  });

  const handleLocationUpdate = () => {
    if (selectedLocationIndex !== null) {
      debugger;
      const updatedLocations = [...updateProfileData.Locations];
      updatedLocations[selectedLocationIndex] = formData; 

      setUpdateProfileData({
        ...updateProfileData,
        Locations: updatedLocations,
      });
      setIsEditModalOpen(false); // Close the modal after saving
    }
  };

  const handleLocationEdit = (index: number, location: LocationType) => {
    setSelectedLocationIndex(index);
    setFormData(location); 
    setIsEditModalOpen(true); 
  };

  const toCamelCase = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const convertKeysToCamelCase = (
    data: TransformedObject
  ): UpdateTransformedObject => {
    const transformedObject: any = {};

    Object.keys(data).forEach((key) => {
      const camelCaseKey = toCamelCase(key);
      transformedObject[camelCaseKey] = data[key as keyof TransformedObject];
    });

    return transformedObject as UpdateTransformedObject;
  };

  const handleBack = async () => {
    // handleLocationUpdate();
    UpdateBusinessProfilesByUniqueID(
      businessData?.UniqueId,
      convertKeysToCamelCase(updateProfileData)
    );
    navigate(-1);
  };

  return (
    <Loader spinning={businessProfilesDataLoading}>
      <div className="edit-profile">
        <ArrowBackIcon
          sx={{
            marginBottom: "12px",
            color: "rgba(0, 0, 0, 0.56)",
            cursor: "pointer",
          }}
          onClick={handleBack}
        />

        {/* Business Info Section */}
        <div className="business-info section">
          <Typography variant="h5">Edit Profile</Typography>
          <Typography variant="body1">Business Info</Typography>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <TextField
              label="Business Name"
              variant="filled"
              fullWidth
              value={updateProfileData?.Name}
              onChange={(e) =>
                setUpdateProfileData({
                  ...updateProfileData,
                  Name: e.target.value,
                })
              }
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Business Website"
              variant="filled"
              fullWidth
              value={updateProfileData?.Website}
              onChange={(e) =>
                setUpdateProfileData({
                  ...updateProfileData,
                  Website: e.target.value,
                })
              }
              sx={{ marginBottom: 2 }}
            />
          </Box>
          <TextField
            label="Business Description"
            variant="filled"
            fullWidth
            multiline
            rows={4}
            value={updateProfileData?.Description}
            onChange={(e) =>
              setUpdateProfileData({
                ...updateProfileData,
                Description: e.target.value,
              })
            }
          />
        </div>
        {/* Modal for Editing Location */}
        <Modal
          open={isEditModalOpen}
          onClose={(e, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            setIsEditModalOpen(false);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: "20px",
              backgroundColor: "white",
              maxHeight: "80vh", 
              overflowY: "auto", 
              width: "400px",
              borderRadius: "8px", 
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", 
            }}
          >
            <h3>Edit Location</h3>
            <TextField
              fullWidth
              label="Address 1"
              name="Address1"
              value={formData.Address1}
              onChange={(e) =>
                setFormData({ ...formData, Address1: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Address 2"
              name="Address2"
              value={formData.Address2}
              onChange={(e) =>
                setFormData({ ...formData, Address2: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Landmark"
              name="LandMark"
              value={formData.LandMark}
              onChange={(e) =>
                setFormData({ ...formData, LandMark: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="City"
              name="City"
              value={formData.City}
              onChange={(e) =>
                setFormData({ ...formData, City: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Zip Code"
              name="ZipCode"
              value={formData.ZipCode}
              onChange={(e) =>
                setFormData({ ...formData, ZipCode: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Region"
              name="Region"
              value={formData.Region}
              onChange={(e) =>
                setFormData({ ...formData, Region: e.target.value })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Country"
              name="Country"
              value={formData.Country}
              onChange={(e) =>
                setFormData({ ...formData, Country: e.target.value })
              }
              margin="normal"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={handleLocationUpdate}
                color="success"
                variant="contained"
                size="large"
              >
                Save
              </Button>
              <Button
                onClick={() => setIsEditModalOpen(false)}
                color="error"
                variant="outlined"
                size="large"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        {/* Locations Section */}
        <div className="locations section">
          <h3>Business Location(s)</h3>
          {updateProfileData.Locations.length > 0 ? (
            updateProfileData.Locations.map((location: any, index: number) => (
              <div key={index} className="location-item">
                <div className="location-details">
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      borderRadius: "4px",
                    }}
                  ></Box>
                  <Box>
                    <Typography variant="h6">
                      {location.Address1 || `Location ${index + 1}`}
                    </Typography>
                    <Typography variant="body1">
                      {location.Address1 ? (
                        <>
                          {location.City}, {location.Region},{location.Country},{" "}
                          {location.ZipCode}
                        </>
                      ) : (
                        `Location ${index + 1}`
                      )}
                    </Typography>
                  </Box>
                </div>

                <div className="actions">
                  <IconButton
                    onClick={() => handleLocationEdit(index, location)}
                  >
                    <EditIcon className="icon" />
                  </IconButton>
                  {/* <IconButton onClick={() => handleDeleteLocation(index)}>
                    <DeleteIcon className="icon" />
                  </IconButton> */}
                </div>
              </div>
            ))
          ) : (
            <Typography variant="body1">
              No locations available for update
            </Typography>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default EditBusinessProfile;
